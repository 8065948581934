<template>
  <div class="p-3 radius-10 bg-white">
    <MdtDiseaseForm @submit="addMdtDiseases" @onClose="onClose" :mdt_disease="mdt_case" :mdt_disease_images="mdt_disease"  :loading="loading" :room="room"/>
  </div>
</template>

<script>
import MdtDiseaseForm from './MdtDiseaseForm.vue'

export default {
  name: 'OrganizationUpdateMdtDisease',
  components: {MdtDiseaseForm},
  data () {
    return {
      room: null,
      loading: false,
      mdt_disease: null,
      mdt_case: null
    }
  },
  mounted () {
    this.showMdtDiseases()
  },
  methods: {
    onClose () {
      this.$router.push({ name: 'OrganizationMdtDiseaseDetail', params: { id: this.$route.params.id } })
    },
    async showMdtDiseases () {
      const res = await this.$rf.getRequest('DoctorRequest').getPublicCaseDetail(this.$route.params.id)
      this.mdt_case = res.data
      this.mdt_disease = res.data.mdt_room_disease
      this.showPublicMdtRoom(res.data.public_mdt_id)
    },
    async addMdtDiseases (formData) {
      this.loading = true
      try {
        formData.set('mdt_room_diseases_id', this.mdt_disease.id)
        formData.set('public_mdt_id', this.mdt_case.public_mdt_id)
        const res = await this.$rf.getRequest('DoctorRequest').updatePublicMdtCase(this.$route.params.id, formData)
        this.goToDetail(res.data.id)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async showPublicMdtRoom (id) {
      this.loading = true
      try {
        const res = await this.$rf.getRequest('DoctorRequest').getPublicMtdRoomDetails(id)
        this.room = res.data
      } catch (e) {
        console.log(e)
        this.$router.push({ name: 'ListMdt' })
      } finally {
        this.loading = false
      }
    },
    goToDetail (id) {
      this.$router.push({ name: 'OrganizationMdtDiseaseDetail', params: { id: id } })
    }
  }
}
</script>

<style scoped>
.form-control {
  background: #ffffff;
  /* Neutral/300 */

  border: 1.5px solid #b4b4c4;
  border-radius: 10px;
  color: #616673;
}
label {
  margin-bottom: 10px;
}
.btn-save {
  height: 50px;
  width: 190px;
  border-radius: 8px;
  padding: 8px, 16px, 8px, 16px;
}
</style>