<template>
  <div>
    <div class="d-flex align-items-center m-3 border-bottom pb-2 mb-4">
      <a class="fs-18 txt-grey-600" @click.prevent="backToDetail">
        {{ room ? room.name : "" }}
        <span class="spinner-border" role="status" v-if="!room">
          <span class="sr-only">Loading...</span>
        </span>
      </a>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mx-2"
      >
        <path
          d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
          fill="#667085"
        />
      </svg>
      <a href="javascript:;" class="fs-18 txt-grey-900 mb-0 disabled">
        {{
        mdt_disease ? "Cập nhật ca bệnh" : "Thêm ca bệnh"
        }}
      </a>
    </div>
    <div class="container">
      <div class="mb-3 d-flex align-items-center">
        <label for="nam" class="d-flex align-items-center mr-4 fs-16 txt-grey-800">
          <input type="radio" id="nam" :value="0" class="mr-2" v-model="form.type" />Hội chẩn
        </label>
        <label for="nu" class="d-flex align-items-center mr-2 fs-16 txt-grey-800">
          <input type="radio" id="nu" :value="1" class="mr-2" v-model="form.type" />Thông qua mổ
        </label>
      </div>
      <div class="mb-3">
        <label for="p-name" class="fs-14 fw-500 txt-grey-900 required">Ngày hội chẩn</label>
        <input v-model="form.date" type="date" class="form-control fs-16 fw-500 txt-grey-900" />
      </div>
      <div class="mb-3">
        <p class="fs-16 fw-600 txt-grey-900">Thông tin bệnh nhân</p>
        <div class="mb-3">
          <p class="fs-16 fw-500 txt-grey-900" v-if="mdt_disease">
            {{ mdt_disease.patient_name }} ({{ mdtUtils.getGender(mdt_disease.patient_gender) }} -
            {{ mdtUtils.getYearOrders(mdt_disease.patient_birthday) }} - {{ mdt_disease.patient_address }})
          </p>
        </div>
      </div>
      <div class="mb-3">
        <p class="fs-16 fw-600 txt-grey-900">Thông tin ca bệnh</p>
        <div class="mb-3">
          <label for="p-name" class="fs-14 fw-500 txt-grey-900 required">Vào viện ngày:</label>
          <input
            v-model="form.admission_date"
            type="date"
            class="form-control fs-16 fw-500 txt-grey-900"
          />
        </div>
        <div class="mb-3">
          <label for="p-name" class="fs-14 fw-500 txt-grey-900 required">Chẩn đoán</label>
          <!-- <p v-html="form.patient_diagnostic" /> -->
          <!-- <EditorInput
            id="patient_diagnostic-mdt"
            :prop_text="form.patient_diagnostic"
            @onChangeContent="(content) => form.patient_diagnostic = content"
          />-->
          <input
            type="text"
            class="form-control fs-16 fw-500 txt-grey-900"
            placeholder
            v-model="form.patient_diagnostic"
          />
        </div>
        <div class="mb-3">
          <label for="p-summary_medical" class="fs-14 fw-500 txt-grey-900">Chỉ định</label>
          <EditorInput
            id="indication-mdt"
            :prop_text="form.indication"
            @onChangeContent="(content) => form.indication = content"
          />
        </div>
        <div class="mb-3">
          <label for="p-summary_medical" class="fs-14 fw-500 txt-grey-900 required">Tóm tắt bệnh án</label>
          <EditorInput
            id="summary_medical-mdt"
            :prop_text="form.summary_medical"
            @onChangeContent="(content) => form.summary_medical = content"
          />
        </div>
        <div class="mb-3">
          <label for="p-anatomic" class="fs-14 fw-500 txt-grey-900">Giải phẫu bệnh</label>
          <EditorInput
            id="anatomic-mdt"
            :prop_text="form.anatomic"
            @onChangeContent="(content) => form.anatomic = content"
          />
        </div>
        <div class="mb-3">
          <label for="p-surgery_method" class="fs-14 fw-500 txt-grey-900">Phương pháp phẫu thuật</label>
          <EditorInput
            id="surgery_method-mdt"
            :prop_text="form.surgery_method"
            @onChangeContent="(content) => form.surgery_method = content"
          />
        </div>
        <div class="mb-3">
          <label for="p-anaesthetic_method" class="fs-14 fw-500 txt-grey-900">Phương pháp vô cảm</label>
          <EditorInput
            id="anaesthetic_method-mdt"
            :prop_text="form.anaesthetic_method"
            @onChangeContent="(content) => form.anaesthetic_method = content"
          />
        </div>
        <div class="mb-3">
          <label for="p-surgical_team" class="fs-14 fw-500 txt-grey-900">Kíp phẫu thuật</label>
          <EditorInput
            id="surgical_team-mdt"
            :prop_text="form.surgical_team"
            @onChangeContent="(content) => form.surgical_team = content"
          />
        </div>
        <div class="mb-3">
          <label for="p-anaesthetic_team" class="fs-14 fw-500 txt-grey-900">Kíp gây mê</label>
          <EditorInput
            id="anaesthetic_team-mdt"
            :prop_text="form.anaesthetic_team"
            @onChangeContent="(content) => form.anaesthetic_team = content"
          />
        </div>
        <div class="mb-3">
          <label for="p-surgery_date" class="fs-14 fw-500 txt-grey-900 required">Ngày mổ dự kiến</label>
          <input
            v-model="form.surgery_date"
            type="date"
            class="form-control fs-16 fw-500 txt-grey-900"
          />
        </div>
        <div class="mb-3">
          <label
            for="p-surgery_date"
            class="fs-14 fw-500 txt-grey-900"
          >Ảnh minh họa ({{ totalImage }}/5)</label>
          <div class="d-flex">
            <div class="upload-container" v-for="(imgObj, i) in imageFiles" :key="i + 'amh'">
              <div class="upload-btn" @click="uploadFile(i)">
                <img v-if="imgObj.url" :src="imgObj.url" alt class="img-x" />
                <template v-else>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.7369 2.76175H8.08489C6.00489 2.75375 4.30089 4.41075 4.25089 6.49075V17.2277C4.20589 19.3297 5.87389 21.0697 7.97489 21.1147C8.01189 21.1147 8.04889 21.1157 8.08489 21.1147H16.0729C18.1629 21.0407 19.8149 19.3187 19.8029 17.2277V8.03775L14.7369 2.76175Z"
                      stroke="#20419B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.4756 2.75V5.659C14.4756 7.079 15.6246 8.23 17.0446 8.234H19.7986"
                      stroke="#20419B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.6416 9.90918V15.9502"
                      stroke="#20419B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.9869 12.2642L11.6419 9.90918L9.29688 12.2642"
                      stroke="#20419B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span class="robo-14-500 txt-pri">Chọn hình ảnh</span>
                </template>
              </div>
              <input v-model="imgObj.name" class="upload-input" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center pb-5">
        <button
          @click="saveMdtDiseases"
          class="btn bg-pri bd-pri text-white btn-save"
          :disabled="
          loading ||
          !form.date ||
          !form.summary_medical ||
          !form.surgery_date ||
          !form.admission_date ||
          isNaN(form.type)
        "
        >{{ mdt_disease ? "Lưu" : "Thêm" }}</button>
      </div>

      <input
        type="file"
        ref="inputFile"
        accept=".jpeg, .jpg"
        @change="handleFileSelected"
        multiple
        class="d-none"
      />
    </div>
  </div>
</template>

<script>
import EditorInput from '@/components/EditorInput.vue'

import mdtUtils from '../../utils/mdtUtils'
import _ from 'lodash'
export default {
  name: 'MdtDiseaseForm',
  props: ['mdt_disease', 'mdt_disease_images', 'loading', 'room'],
  components: { EditorInput },
  data () {
    return {
      mdtUtils,
      form: {
        type: 0,
        date: '',
        patient_diagnostic: '',
        summary_medical: '',
        admission_date: '',
        anatomic: '',
        surgery_method: '',
        anaesthetic_method: '',
        surgical_team: '',
        anaesthetic_team: '',
        surgery_date: '',
        indication: ''
      },
      imageFiles: {},
      currentImage: 1
    }
  },
  watch: {
    mdt_disease (data) {
      if (data) {
        this.form = {
          type: data.type,
          date: data.mdt_date,
          patient_diagnostic: this.patientDiagnostic(data) || '',
          summary_medical: this.patientDiseasesCurrentCure(data) || '',
          admission_date: data.admission_date,
          anatomic: data.anatomic || '',
          surgery_method: data.surgery_method || '',
          anaesthetic_method: data.anaesthetic_method || '',
          surgical_team: data.surgical_team || '',
          anaesthetic_team: data.anaesthetic_team || '',
          surgery_date: data.surgery_date,
          indication: data.indication || ''
        }
      } else {
        this.form = {
          type: 0,
          date: '',
          patient_diagnostic: '',
          summary_medical: '',
          admission_date: '',
          anatomic: '',
          surgery_method: '',
          anaesthetic_method: '',
          surgical_team: '',
          anaesthetic_team: '',
          surgery_date: '',
          indication: ''
        }
      }
      this.makeImageSize()
    }
  },
  computed: {
    totalImage () {
      let total = 0
      for (const i in this.imageFiles) {
        const img = this.imageFiles[i]
        if (img.url) total++
      }
      return total
    }
  },
  methods: {
    onClose () {
      this.$emit('onClose')
    },
    async saveMdtDiseases () {
      const { form, imageFiles } = this
      const formData = new FormData()
      for (const key in form) {
        if (!_.isNil(form[key])) formData.set(key, form[key])
      }
      for (const i in imageFiles) {
        const imgObj = imageFiles[i]
        if (!imgObj) continue
        if (imgObj.fileRaw) formData.set('image_' + i, imgObj.fileRaw)
        if (imgObj.name) formData.set('image_name_' + i, imgObj.name)
      }
      this.$emit('submit', formData)
    },
    backToDetail () {
      this.$router.push({
        name: 'ListMdt',
        query: { mdtid: this.room?.id, type: 'ORG' }
      })
    },
    makeImageSize () {
      const images = {}
      for (let i = 1; i <= 5; i++) {
        images[i] = {
          url: (this.mdt_disease && this.mdt_disease['image_url_' + i]) || (this.mdt_disease_images && this.mdt_disease_images['image_url_' + i]),
          name: (this.mdt_disease && this.mdt_disease['image_name_' + i]) || (this.mdt_disease_images && this.mdt_disease_images['image_url_' + i])
        }
      }
      this.imageFiles = images
    },
    uploadFile (index) {
      this.currentImage = index
      this.$refs.inputFile.click()
    },
    handleFileSelected (evt) {
      const file = evt.target.files[0]
      const { currentImage: index } = this
      this.$set(this.imageFiles, index, {
        ...this.imageFiles[index],
        url: URL.createObjectURL(file),
        fileRaw: file
      })
      evt.target.value = null
    },
    patientDiagnostic (data) {
      const mdt_room_disease = data?.mdt_room_disease
      if (data.patient_diagnostic) {
        return data.patient_diagnostic
      }

      if (mdt_room_disease?.multilang?.length) {
        const langId = this.langId
        const r = mdt_room_disease?.multilang.find(
          item => item.lang === langId
        )
        if (!r) return mdt_room_disease?.patient_diagnostic
        else return r.patient_diagnostic
      }
      return mdt_room_disease?.patient_diagnostic
    },
    patientDiseasesCurrentCure (data) {
      const mdt_room_disease = data?.mdt_room_disease
      if (data.summary_medical) {
        return data.summary_medical
      }

      if (mdt_room_disease?.multilang?.length) {
        const langId = this.langId
        const r = mdt_room_disease?.multilang.find(
          item => item.lang === langId
        )
        if (!r) return mdt_room_disease?.patient_diseases_current_cure
        else return r.patient_diseases_current_cure
      }
      return mdt_room_disease?.patient_diseases_current_cure
    }
  }
}
</script>

<style scoped>
.form-control fs-16 fw-500 txt-grey-900 {
  background: #ffffff;
  /* Neutral/300 */

  border: 1.5px solid #b4b4c4;
  border-radius: 10px;
  color: #616673;
}

label {
  margin-bottom: 10px;
}

.btn-save {
  height: 50px;
  width: 190px;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
}

.upload-btn {
  border: 0.756458px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
}

.upload-container {
  width: 50%;
  padding: 0.5%;
  margin-right: 16px;
}

.upload-btn:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.upload-input {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #03014c;
  margin-top: 10px;
}
</style>
